var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{ref:"addToServiceQueuQueueFormContainer",staticClass:"pa-0 pt-0",attrs:{"fluid":""}},[_c('v-form',{ref:"form",model:{value:(_vm.formIsValid),callback:function ($$v) {_vm.formIsValid=$$v},expression:"formIsValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.isProspectType)?_c('Field',{attrs:{"label":_vm.$t('crm.addToServiceQueuQueueForm.prospect')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
return [_c('v-autocomplete',{attrs:{"items":_vm.clientOptions,"label":label,"item-text":function (model) { return ("" + (model.nome)); },"return-object":true,"hide-details":"auto","disabled":""},model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}})]}}],null,false,3843761892)}):_vm._e(),(!_vm.isProspectType)?_c('Field',{attrs:{"label":_vm.$t('crm.addToServiceQueuQueueForm.client')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
return [_c('v-autocomplete',{attrs:{"items":_vm.clientOptions,"label":label,"item-text":function (model) { return ((model.cnpjCpf) + " - " + (model.nome)); },"return-object":true,"hide-details":"auto","disabled":""},model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}})]}}],null,false,918961032)}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('Field',{attrs:{"label":_vm.$t('crm.addToServiceQueuQueueForm.serviceQueue'),"rules":_vm.rules.serviceQueueType},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('v-autocomplete',{attrs:{"items":_vm.serviceQueueTypeOptions,"rules":rules,"label":label,"item-text":"nome","return-object":true,"hide-details":"auto"},model:{value:(_vm.serviceQueueType),callback:function ($$v) {_vm.serviceQueueType=$$v},expression:"serviceQueueType"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('Field',{attrs:{"label":_vm.$t('crm.addToServiceQueuQueueForm.description'),"rules":_vm.rules.description},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('WysiwygEditor',{attrs:{"rules":rules,"label":label,"extensions":_vm.editorExtensions,"hide-details":"auto","min-height":"80","max-height":"120"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.onSave}},[_c('span',[_vm._v(_vm._s(_vm.$t('global.save')))])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.onCancel}},[_c('span',[_vm._v(_vm._s(_vm.$t('global.cancel')))])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }