



































import { Vue, Component, Emit } from 'vue-property-decorator';
import { FormHelper } from '@/utils/helpers/form-helper';

@Component({})
export default class CrmChatSelectEmoticonForm extends Vue {
  selectedEmoticons: string[] = [];

  availableEmoticons = [
    '😀',
    '😃',
    '😄',
    '😁',
    '😆',
    '😅',
    '😂',
    '🤣',
    '😊',
    '😇',
    '🙂',
    '🙃',
    '😉',
    '😌',
    '😍',
    '🥰',
    '😘',
    '😋',
    '🤪',
    '🤨',
    '🧐',
    '😎',
    '🤩',
    '🥳',
    '😔',
    '😕',
    '🙁',
    '☹️',
    '😣',
    '😖',
    '😫',
    '🥺',
    '😢',
    '😭',
    '😳',
    '🥵',
    '😱',
    '😨',
    '😰',
    '😥',
    '😓',
    '🤗',
    '🤔',
    '🤭',
    '😬',
    '😧',
    '😮',
    '😲',
    '🙌',
    '👏',
    '🤝',
    '👍',
    '👎',
    '👊',
    '✌️',
    '🤟',
    '🤘',
    '👌',
    '👈',
    '👉',
    '👆',
    '☝️',
    '🤙',
    '💪',
    '🙏',
    '🔝',
  ];

  onSend(): void {
    this.$emit('select', this.selectedEmoticons.join(''));
  }

  onClickIcon(value: string): void {
    this.selectedEmoticons.push(value);
  }

  @Emit('cancel')
  onCancel(): void {
    FormHelper.resetValidation(this.$refs.form as Vue);
  }
}
