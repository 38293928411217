var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ContentDialog',{attrs:{"hideCloseButton":true,"title":_vm.title,"max-width":"800px"},on:{"after-close":_vm.onAfterCloseFinishCallDialog},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"tile":"","elevation":"0","loading":_vm.loading}},[_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.formIsValid),callback:function ($$v) {_vm.formIsValid=$$v},expression:"formIsValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('Field',{attrs:{"label":_vm.$t('crm.callCenter.finishCallDialog.origin'),"rules":_vm.rules.origin},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('v-autocomplete',{attrs:{"items":_vm.originOptions,"rules":rules,"label":label,"item-text":"descricao","item-value":"id","return-object":true,"hide-details":"auto"},model:{value:(_vm.model.origem),callback:function ($$v) {_vm.$set(_vm.model, "origem", $$v)},expression:"model.origem"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('Field',{attrs:{"label":_vm.$t('crm.callCenter.finishCallDialog.group'),"rules":_vm.rules.grupo},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('v-autocomplete',{attrs:{"items":_vm.groupOptions,"rules":rules,"label":label,"item-text":"descricao","item-value":"id","return-object":true,"hide-details":"auto"},on:{"change":_vm.onGroupChange},model:{value:(_vm.model.grupoArea),callback:function ($$v) {_vm.$set(_vm.model, "grupoArea", $$v)},expression:"model.grupoArea"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('Field',{attrs:{"label":_vm.$t('crm.callCenter.finishCallDialog.historyType'),"rules":_vm.rules.historyType},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('v-autocomplete',{attrs:{"items":_vm.historyTypeOptions,"rules":rules,"label":label,"item-text":"nome","item-value":"id","return-object":true,"hide-details":"auto","loading":_vm.loadingHistoryTypeOptions},model:{value:(_vm.model.tipoHistorico),callback:function ($$v) {_vm.$set(_vm.model, "tipoHistorico", $$v)},expression:"model.tipoHistorico"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('Field',{attrs:{"label":_vm.$t('crm.callCenter.finishCallDialog.description'),"rules":_vm.rules.description},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('WysiwygEditor',{attrs:{"rules":rules,"label":label,"hide-details":"auto","min-height":"120","max-height":"180"},model:{value:(_vm.model.descricao),callback:function ($$v) {_vm.$set(_vm.model, "descricao", $$v)},expression:"model.descricao"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('Field',{attrs:{"label":_vm.$t('crm.callCenter.finishCallDialog.result'),"rules":_vm.rules.result},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('v-autocomplete',{attrs:{"readonly":_vm.mistake,"items":_vm.resultOptions,"rules":rules,"label":label,"item-text":"description","item-value":"id","hide-details":"auto"},model:{value:(_vm.model.resultado),callback:function ($$v) {_vm.$set(_vm.model, "resultado", $$v)},expression:"model.resultado"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.onSave}},[_c('span',[_vm._v(_vm._s(_vm.$t('global.save')))])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }