
























import { Vue, Component, VModel } from 'vue-property-decorator';
import CallCenterQueueInformationModel from '@/models/crm/call-center-queue-information.model';

@Component({
  components: {},
})
export default class CrmCallCenterQueue extends Vue {
  @VModel()
  itens!: CallCenterQueueInformationModel[];
}
