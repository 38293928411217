
















import { Vue, Component, Prop } from 'vue-property-decorator';
import { INavData } from '@/interfaces/nav-data.interface';

@Component({})
export default class NavBarBtn extends Vue {
  @Prop({ required: true })
  readonly item!: INavData;

  @Prop()
  readonly icon!: boolean;

  @Prop()
  readonly disabled!: boolean;

  get route(): { name: string } | null {
    return !this.disabled && !this.item.disabled && this.item.route
      ? { name: this.item.route }
      : null;
  }

  get action(): CallableFunction | null {
    return !this.disabled && !this.item.disabled && this.item.action ? this.item.action : () => {};
  }
}
