var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ContentDialog',{attrs:{"hideCloseButton":true,"title":_vm.title,"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"tile":"","elevation":"0"}},[_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.formIsValid),callback:function ($$v) {_vm.formIsValid=$$v},expression:"formIsValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('Field',{attrs:{"label":_vm.$t('crm.callCenter.transferCallDialog.attendants'),"rules":_vm.attendantsRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('v-autocomplete',{attrs:{"items":_vm.callCenterUsers,"rules":rules,"label":label,"item-text":"usuario","item-value":"idUsuario","return-object":true,"hide-details":"auto"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(item.usuario)+" ")]),_c('v-col',{attrs:{"cols":"6"}},[(item.disponivel)?_c('div',[(!item.status)?_c('div',[_c('v-icon',{attrs:{"large":"","color":"#4CAF50"}},[_vm._v("mdi-circle-small")]),_c('span',[_vm._v(" Disponível")])],1):_c('div',[_c('v-icon',{attrs:{"large":"","color":"#FF9800"}},[_vm._v("mdi-circle-small")]),_c('span',[_vm._v(" "+_vm._s(item.status))])],1)]):_c('div',[_c('v-icon',{attrs:{"large":"","color":"#CCCCCC"}},[_vm._v("mdi-circle-small")]),_c('span',[_vm._v(" Indisponível")])],1)])],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(item.usuario)+" ")])],1)]}}],null,true),model:{value:(_vm.attendants),callback:function ($$v) {_vm.attendants=$$v},expression:"attendants"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"orange darken-1 white--text mt-2",on:{"click":_vm.onSave}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-swap-horizontal")]),_vm._v(" "+_vm._s(_vm.$t('crm.callCenter.transferCallDialog.transfer'))+" ")],1),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.onCancel}},[_c('span',[_vm._v(_vm._s(_vm.$t('global.cancel')))])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }