









































import { Vue, Component, Emit } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { LoaderComponent } from 'vue-loading-overlay';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { FormHelper } from '@/utils/helpers/form-helper';
import { ValidationRules } from '@/utils/helpers/validation-rules-helper';
import Field from '@/components/field.vue';
import ConversationService from '@/services/crm/conversation.service';
import { IKeyValue } from '@/interfaces/key-value.interface';
import ConversationQuickAnswerModel from '@/models/crm/conversation-quick-answer.model';

@Component({
  components: {
    Field,
  },
})
export default class CrmChatSelectQuickAnswerForm extends Vue {
  @inject(InjectionIdEnum.CrmConversationService)
  private conversationService!: ConversationService;

  formIsValid = true;

  model: IKeyValue<string | null> = {
    quickAnswer: null,
  };

  rules = {
    quickAnswer: [ValidationRules.required],
  };

  quickAnswerOptions: ConversationQuickAnswerModel[] = [];

  async mounted(): Promise<void> {
    const loader = this.setBusyLoader();
    try {
      const getQuickAnswersTask = this.loadQuickAnswers();
      await Promise.all([getQuickAnswersTask]);
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  async onSend(): Promise<void> {
    if (FormHelper.validate(this.$refs.form as Vue)) {
      const loader = this.setBusyLoader();
      try {
        this.$emit('select', this.model.quickAnswer);
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        loader.hide();
      }
    }
  }

  @Emit('cancel')
  onCancel(): void {
    FormHelper.resetValidation(this.$refs.form as Vue);
  }

  private async loadQuickAnswers(): Promise<void> {
    this.quickAnswerOptions = await this.conversationService.getQuickAnswers();
  }

  private setBusyLoader(): LoaderComponent {
    return this.$loading.show({
      container: this.$refs.selectQuickAnswerFormContainer,
      canCancel: false,
    });
  }
}
