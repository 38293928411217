

















import { Vue, Component, Prop } from 'vue-property-decorator';
import { INavData } from '@/interfaces/nav-data.interface';

@Component({})
export default class NavBarMenuItem extends Vue {
  @Prop({ required: true })
  readonly item!: INavData;

  get route(): { name: string } | null {
    return !this.item.disabled && this.item.route ? { name: this.item.route } : null;
  }
}
