


















































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { LoaderComponent } from 'vue-loading-overlay';
import { inject } from 'inversify-props';
import { HardBreak } from 'tiptap-vuetify';
import Field from '@/components/field.vue';
import { FormHelper } from '@/utils/helpers/form-helper';
import ClientModel from '@/models/crm/client.model';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { ValidationRules } from '@/utils/helpers/validation-rules-helper';
import WysiwygEditor from '@/components/wysiwyg-editor.vue';
import ServiceQueueService from '@/services/crm/service-queue.service';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import ServiceQueueTypeModel from '@/models/crm/service-queue-type.model';

@Component({
  components: {
    Field,
    WysiwygEditor,
  },
})
export default class CrmAddToServiceQueueForm extends Vue {
  @inject(InjectionIdEnum.CrmServiceQueueService)
  private serviceQueueService!: ServiceQueueService;

  @Prop({ required: true })
  client!: ClientModel;

  @Prop({ required: true })
  clientType!: ClientTypeEnum;

  serviceQueueType: ServiceQueueTypeModel | null = null;

  description = '';

  serviceQueueTypeOptions: ServiceQueueTypeModel[] = [];

  clientOptions: ClientModel[] = [];

  rules: IKeyValue<CallableFunction[]> = {
    serviceQueueType: [ValidationRules.required],
  };

  editorExtensions = [HardBreak];

  async mounted(): Promise<void> {
    const loader = this.setBusyLoader();
    try {
      this.clientOptions = [this.client];
      this.serviceQueueTypeOptions = await this.serviceQueueService.getTypes();
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  async onSave(): Promise<void> {
    if (FormHelper.validate(this.$refs.form as Vue)) {
      const loader = this.setBusyLoader();
      try {
        await this.serviceQueueService.addToServiceQueue(
          this.client,
          this.clientType,
          this.serviceQueueType as ServiceQueueTypeModel,
          this.description,
        );

        this.$notify.success(
          this.$t('crm.addToServiceQueuQueueForm.successfullyAdded', { clientType: this.$t(`crm.${this.clientType}`) }),
        );

        this.$emit('complete', true);
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        loader.hide();
      }
    }
  }

  @Emit('cancel')
  onCancel(): void {
    FormHelper.resetValidation(this.$refs.form as Vue);
  }

  get isProspectType(): boolean {
    return this.clientType === ClientTypeEnum.Prospect;
  }

  private setBusyLoader(): LoaderComponent {
    return this.$loading.show({
      container: this.$refs.addToServiceQueuQueueFormContainer,
      canCancel: false,
    });
  }
}
