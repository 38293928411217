















































import { Vue, Component, VModel, Watch } from 'vue-property-decorator';
import { LoaderComponent } from 'vue-loading-overlay';
import { inject } from 'inversify-props';
import { AgGridVue } from '@ag-grid-community/vue';
import ContentDialog from '@/components/content-dialog.vue';
import AgGridWrapper from '../ag-grid-wrapper.vue';
import CallCenterUserModel from '@/models/crm/call-center-user.model';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import CallCenterUserService from '@/services/crm/call-center-user.service';

@Component({
  components: {
    ContentDialog,
    AgGridWrapper,
    AgGridVue,
  },
})
export default class CrmCallCenterUserStatusDialog extends Vue {
  @inject(InjectionIdEnum.CallCenterUserService)
  private callCenterUserService!: CallCenterUserService;

  @VModel()
  dialog!: boolean;

  callCenterUsers: CallCenterUserModel[] = [];

  title = this.$t('crm.callCenter.userStatusDialog.title');

  busyLoaderBreak!: LoaderComponent;

  @Watch('dialog')
  async onDialogStatusChange() {
    this.callCenterUsers = [];
    if (this.dialog) {
      const busy = this.setBusyLoader();
      this.callCenterUsers = await this.callCenterUserService.getAll();
      busy.hide();
    }
  }

  private setBusyLoader(): LoaderComponent {
    return this.$loading.show({
      container: this.$refs.callCenterUserStatus,
      canCancel: false,
    });
  }
}
