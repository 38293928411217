import { Extension } from 'tiptap';

export default class EnterInterceptor extends Extension {
  private onKeyDownFunction: CallableFunction;

  constructor(onKeyDownFunction: CallableFunction) {
    super();
    this.onKeyDownFunction = onKeyDownFunction;
  }

  keys(): unknown {
    const { onKeyDownFunction } = this;
    return {
      Enter() {
        if (onKeyDownFunction) {
          onKeyDownFunction();
        }
        return true;
      },
    };
  }
}
