














































































import { Vue, Component, VModel, Emit, Prop, Watch } from 'vue-property-decorator';
import AttachmentModel from '@/models/crm/attachment.model';
import { IKeyValue } from '@/interfaces/key-value.interface';

@Component({})
export default class CrmAttachmentsViewerDetailsDialog extends Vue {
  model = 0;

  @VModel()
  dialog!: boolean;

  @Prop({ required: true })
  items!: AttachmentModel[];

  @Prop()
  activeItem!: AttachmentModel;

  @Watch('activeItem')
  onActiveItemChange(item: AttachmentModel): void {
    this.model = (this.items || []).findIndex((x) => x.type === item.type && x.id === item.id) || 0;
  }

  @Emit()
  afterClose(): boolean {
    return this.dialog;
  }

  close(): void {
    this.dialog = false;
  }

  get fileTypes(): IKeyValue {
    const mappedTypes: IKeyValue = {};

    this.items.forEach((file) => {
      const filename = file.name || file.path;

      const regexp = new RegExp(/\.[0-9a-z]{1,5}$/i);
      const regexpMatch = filename.match(regexp) || [];

      const typeMatrix = {
        '.jpg': 'image',
        '.jpeg': 'image',
        '.gif': 'image',
        '.png': 'image',
        '.mp4': 'video',
        '.pdf': 'pdf',
        '.mp3': 'audio',
      };

      mappedTypes[`${file.type}_${file.id}`] = typeMatrix[regexpMatch[0]] || 'unknown';
    });

    return mappedTypes;
  }
}
