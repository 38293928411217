








































import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import { LoaderComponent } from 'vue-loading-overlay';
import Field from '@/components/field.vue';
import { IValidationRules } from '@/interfaces/validation-rules.interface';
import { ValidationRules } from '@/utils/helpers/validation-rules-helper';
import { FormHelper } from '@/utils/helpers/form-helper';

@Component({
  components: {
    Field,
  },
})
export default class CrmBlockNumberForm extends Vue {
  @Prop()
  contactNumber!: string;

  rules: IValidationRules = {
    maxLengthInChars: [[ValidationRules.maxLength, 60]],
  };

  reason: string | null = null;

  formIsValid = true;

  async onSend(): Promise<void> {
    if (FormHelper.validate(this.$refs.form as Vue)) {
      const loader = this.setBusyLoader();
      try {
        this.$emit('select', this.reason);
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        loader.hide();
      }
    }
  }

  @Emit('cancel')
  onCancel(): void {
    FormHelper.resetValidation(this.$refs.form as Vue);
  }

  private setBusyLoader(): LoaderComponent {
    return this.$loading.show({
      container: this.$refs.blockNumberContainer,
      canCancel: false,
    });
  }
}
