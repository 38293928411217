







































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { camelCase, padStart } from 'lodash';
import SimpleTable from '@/components/simple-table.vue';

@Component({
  components: {
    SimpleTable,
  },
})
export default class AudioRecorder extends Vue {
  @Prop()
  small!: boolean;

  @Prop()
  large!: boolean;

  @Prop()
  disabled!: boolean;

  checking = false;

  active = false;

  recorder!: MediaRecorder;

  recordingTime = 0;

  private isCancelAction = false;

  @Watch('active')
  onActiveChange(value: boolean): void {
    if (value) {
      this.counter();
    } else {
      this.recordingTime = 0;
    }
  }

  onTriggerAction(): void {
    if (!this.active && !this.checking) {
      this.checking = true;

      // Request permissions to record audio
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          this.checking = false;
          this.active = true;

          this.$emit('start');

          this.recorder = new MediaRecorder(stream, {
            mimeType: 'audio/mpeg',
          });

          // When recording will be finished
          this.recorder.addEventListener('dataavailable', (e) => {
            this.$emit('finish');

            this.checking = false;
            this.active = false;

            if (!this.isCancelAction) {
              this.$emit('receive', e.data);
            }

            this.isCancelAction = false;
          });

          this.recorder.addEventListener('error', (e) => {
            console.error('Error on record audio', e);
          });

          // Start recording
          this.recorder.start();
        })
        .catch((err) => {
          this.checking = false;
          this.active = false;

          this.$emit('finish');

          const message = err && (err as Error).message;
          this.$notify.error(this.$t(`global.audioRecorder.messages.${camelCase(message)}`));
        });
    } else if (this.recorder) {
      // Stop recording
      this.recorder.stop();

      // Remove "recording" icon from browser tab
      this.recorder.stream.getTracks().forEach((i) => i.stop());
    }
  }

  onCancelRecorder(): void {
    if (this.active) {
      this.isCancelAction = true;

      // Stop recording
      this.recorder.stop();

      // Remove "recording" icon from browser tab
      this.recorder.stream.getTracks().forEach((i) => i.stop());
    }
  }

  get formattedRecordingTime(): string {
    const minutes = Math.floor(this.recordingTime / 60);
    const seconds = this.recordingTime % 60;
    return `${padStart(minutes.toString(), 2, '0')}:${padStart(seconds.toString(), 2, '0')}`;
  }

  private counter(): void {
    setTimeout(() => {
      if (this.active) {
        this.recordingTime += 1;
        this.counter();
      }
    }, 1000);
  }
}
