import CryptoJS from 'crypto-js/crypto-js';

export abstract class FileHelper {
  static filterByKeyword<T>(items: T[], searchableKeys: string[], keyword = ''): T[] {
    const regexp = new RegExp(keyword, 'i');

    return items.filter((item) => {
      const result = searchableKeys.find((column) => (item[column] || '').toString().search(regexp) > -1);
      return result;
    });
  }

  static getChecksum(blob: Blob | File, progressCallback?: CallableFunction): Promise<string> {
    const result = new Promise<string>((resolve, reject) => {
      const md5 = CryptoJS.algo.MD5.create();
      FileHelper.readChunked(
        blob,
        (chunk: Blob, offs: number, total: number) => {
          md5.update(CryptoJS.enc.Latin1.parse(chunk));
          if (progressCallback) {
            progressCallback(offs / total);
          }
        },
        (err) => {
          if (err) {
            reject(err);
          } else {
            const hash = md5.finalize();
            const hashHex = hash.toString(CryptoJS.enc.Hex);
            resolve(hashHex);
          }
        },
      );
    });
    return result;
  }

  private static readChunked(file: Blob | File, chunkCallback: CallableFunction, endCallback: CallableFunction) {
    const fileSize = file.size;
    const chunkSize = 4 * 1024 * 1024; // 4MB
    let offset = 0;
    const reader = new FileReader();

    const readNext = () => {
      const fileSlice = file.slice(offset, offset + chunkSize);
      reader.readAsBinaryString(fileSlice);
    };

    reader.onload = () => {
      if (reader.error) {
        endCallback(reader.error || {});
        return;
      }
      offset += (reader?.result as string)?.length || 0;
      chunkCallback(reader.result, offset, fileSize);
      if (offset >= fileSize) {
        endCallback(null);
        return;
      }
      readNext();
    };

    reader.onerror = (err) => {
      endCallback(err || {});
    };

    readNext();
  }
}
