



































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { IChatSendMediaItem } from '@/interfaces/crm/chat-send-media-item.interface';
import { ConversationMessageTypeEnum } from '@/enums/crm/conversation-message-type.enum';
import { StringHelper } from '@/utils/helpers/string-helper';

@Component({
  components: {},
})
export default class CrmChatMediaPreview extends Vue {
  @Prop({ required: true })
  previewType!: 'photoAndVideo' | 'document';

  @Prop({ required: true })
  files!: File[];

  fileItems: IChatSendMediaItem[] = [];

  fileTypes = {
    'image/jpg': 'photo',
    'image/jpeg': 'photo',
    'image/png': 'photo',
    'video/mp4': 'video',
    'video/3gpp': 'video',
  };

  caption = '';

  async mounted(): Promise<void> {
    const items: Promise<IChatSendMediaItem>[] = [];

    this.files.forEach((file) => items.push(this.mapFileItem(file)));

    this.fileItems = await Promise.all(items);
  }

  onDeleteMedia(item: IChatSendMediaItem): void {
    this.fileItems = this.fileItems.filter(
      (x) => (x.file as File).name !== (item.file as File).name && x.file.size !== item.file.size,
    );
  }

  onCancel(): void {
    this.$emit('cancel');
  }

  onSend(): void {
    if (this.caption) {
      this.fileItems[0].caption = this.caption;
    }

    this.$emit('send', this.fileItems);
  }

  get showDeleteBtn(): boolean {
    return this.fileItems.length > 1;
  }

  get disableSendBtn(): boolean {
    return !this.fileItems.length;
  }

  private async mapFileItem(file: File): Promise<IChatSendMediaItem> {
    const dataUri = await CrmChatMediaPreview.getBase64(file);

    let type = ConversationMessageTypeEnum.Document;

    if (this.previewType === 'photoAndVideo') {
      if (this.fileTypes[file.type] === 'photo') {
        type = ConversationMessageTypeEnum.Image;
      } else if (this.fileTypes[file.type] === 'video') {
        type = ConversationMessageTypeEnum.Video;
      }
    }

    return {
      file,
      dataUri,
      caption: '',
      filename: StringHelper.formatFilename(file.name),
      type,
    };
  }

  public static getBase64(file: File): Promise<string> {
    const reader = new FileReader();

    return new Promise((resolve) => {
      reader.onload = (ev) => {
        resolve(ev.target?.result as string);
      };
      reader.readAsDataURL(file);
    });
  }
}
