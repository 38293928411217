






































import { Vue, Component } from 'vue-property-decorator';
import { CallCenterEventEnum } from '@/enums/crm/call-center-event.enum';
import CallCenterUserModel from '@/models/crm/call-center-user.model';
import CallCenterUserBreakModel from '@/models/crm/call-center-user-break.model';

@Component({
  components: {},
})
export default class CrmCallCenterUserStatusCard extends Vue {
  title = this.$t('crm.callCenter.userStatusCard.title');

  callCenterUser: CallCenterUserModel | null = null;

  reasonBreak: CallCenterUserBreakModel | null = null;

  get showUserStatusCard(): boolean {
    return this.callCenterUser != null;
  }

  created() {
    this.$callCenter.subscribe.$on(CallCenterEventEnum.UserLogged, (user: CallCenterUserModel) => {
      this.callCenterUser = user;
    });

    this.$callCenter.subscribe.$on(CallCenterEventEnum.UserLogout, () => {
      this.callCenterUser = null;
    });

    this.$callCenter.subscribe.$on(CallCenterEventEnum.Break, (reasonBreak: CallCenterUserBreakModel) => {
      this.reasonBreak = reasonBreak;
    });

    this.$callCenter.subscribe.$on(CallCenterEventEnum.Avaiable, () => {
      this.reasonBreak = null;
    });
  }
}
